import * as React from "react"
import {graphql} from "gatsby";
import Layout from "../components/layout"
import {Box, Container, Flex, Heading, Link, Text} from "../components/ui";
import * as sections from "../components/sections";
import Fallback from "../components/fallback";



export default function Shop(props) {
	const { shopPage } = props.data

	return (
		<Layout {...shopPage}>
			<Box paddingY={5}>
				<Container width="narrow">
					<Heading as="h1">{shopPage.title}</Heading>
					<Text as="p">The shop has moved - <a href="https://www.playhq.com/basketball-victoria/org/ocean-grove-breakers-basketball-club/9146c347/shop">visit PlayHQ</a></Text>
					
					{/*{shopPage.blocks.map((block) => {*/}
					{/*	const { id, blocktype, ...componentProps } = block*/}
					{/*	const Component = sections[blocktype] || Fallback*/}
					{/*	return <Component key={id} {...componentProps} />*/}
					{/*})}*/}

				</Container>
			</Box>
		</Layout>


	)
}


export const query = graphql`
  {
    shopPage {
      id
      title
      blocks: content {
        id
        blocktype
        ...HomepageProductListContent
      }
    }
  }
`
